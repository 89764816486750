import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useMemo } from 'react';
import { useConfigStore } from './store/config.store';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { APPLICATION_ID } = useConfigStore();

  const pca = useMemo<PublicClientApplication | null>(() => {
    if (!APPLICATION_ID) return null;
    return new PublicClientApplication({
      auth: {
        clientId: APPLICATION_ID,
        authority: 'https://login.microsoftonline.com/organizations',
        redirectUri: `${window.location.origin}/callback`,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    });
  }, [APPLICATION_ID]);

  if (!pca) return null;

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};
