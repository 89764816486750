import { useAppStore } from '@/store/app.store';
import { FC } from 'react';

import Logo from '../assets/Logo';
import { classNames } from '@/utils/classNames';

type HeaderProps = {
  children?: React.ReactNode;
};

export const Header: FC<HeaderProps> = ({ children }) => {
  const theme = useAppStore((state) => state.theme);

  return (
    <div className="flex flex-col items-center gap-10 pt-8 text-primary">
      <Logo
        className={classNames(
          'w-3/5',
          theme === 'ronnebyDark' ? 'fill-white' : 'fill-[#040606]',
        )}
      />
      <p className="text-center text-sm font-normal text-accent">{children}</p>
    </div>
  );
};
