import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface Config {
  ADMIN_URL: string;
  API_URL: string;
  APPLICATION_ID: string;
  initialized: boolean;
  setConfig: (config: Partial<Config>) => void;
}

export const useConfigStore = create<Config>()(
  devtools((set) => ({
    ADMIN_URL: 'https://api.allq.dev.allbin.se',
    API_URL: 'https://remote.techinova.se',
    APPLICATION_ID: '81185295-112d-4b80-a609-f95b8121b90a',
    initialized: false,
    setConfig: (config) => set({ ...config, initialized: true }),
  })),
);
