import { FC, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Attendance } from './pages/Attendance';
import { ReportAttendance } from './pages/attendance/ReportAttendance';
import { ReportError } from './pages/attendance/ReportError';
import { ReportSuccess } from './pages/attendance/ReportSuccess';
import { ErrorPage } from './pages/ErrorPage';
import { useQuery } from '@tanstack/react-query';
import { useConfigStore } from './store/config.store';
import { ProtectedRoute } from './ProtectedRoute';
import { Callback } from './pages/Callback';
import Spinner from './components/Spinner';

export interface RouterParams {
  [key: string]: string;
  qrId: string;
}

const router = createBrowserRouter([
  {
    path: 'callback',
    element: <Callback />,
  },
  {
    path: 'attendance/:qrId',
    element: (
      <ProtectedRoute>
        <Attendance />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <ReportAttendance />,
      },
      {
        path: 'success',
        element: <ReportSuccess />,
      },
      {
        path: 'error',
        element: <ReportError />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]);

const App: FC = () => {
  const { setConfig, isConfigLoaded } = useConfigStore((state) => ({
    setConfig: state.setConfig,
    isConfigLoaded: state.initialized,
  }));

  const {
    data: config,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['config'],
    queryFn: async () => {
      const response = await fetch('/config.json');
      if (!response.ok) {
        throw new Error('Something went wrong, please try again later');
      }
      return response.json();
    },
  });

  useEffect(() => {
    if (!config) return;
    setConfig(config);
  }, [config, setConfig]);

  if (isFetching || !isConfigLoaded) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!config || !isConfigLoaded || error) {
    return <div>Error loading config</div>;
  }

  return <RouterProvider router={router} />;
};

export default App;
