import { useQuery } from '@tanstack/react-query';
import { useConfigStore } from '@/store/config.store';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

export interface QrData {
  id: string;
  name: string;
  externalId: string;
  callback: string;
  companyId: string;
  groupId: string;
}

const fetchResource = async (
  apiUrl: string,
  accessToken: string,
  resourceId: string,
): Promise<QrData> => {
  const response = await fetch(`${apiUrl}/api/resource/${resourceId ?? ''}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status === 404) {
    throw new Error('Resource not found');
  }

  if (!response.ok) {
    throw new Error('Something went wrong, please try again later');
  }

  const data = (await response.json()) as Array<QrData>;
  if (!data[0]) {
    throw new Error('Resource not found');
  }
  return data[0];
};

export const useResource = (id?: string) => {
  const { adminUrl } = useConfigStore((state) => ({
    adminUrl: state.ADMIN_URL,
  }));

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  return useQuery({
    queryKey: ['resource', id, adminUrl, accounts[0]],
    queryFn: async () => {
      const token = await instance.acquireTokenSilent({
        scopes: [],
        account: accounts[0],
      });
      return fetchResource(adminUrl, token.accessToken ?? '', id ?? '');
    },
    enabled: isAuthenticated && !!id,
  });
};
