import { QrData } from '@/utils/useResource';

interface Props {
  resource?: QrData;
}

export const QRIdDisplay: React.FC<Props> = ({ resource }) => {
  if (!resource) return null;

  return (
    <div className="flex grow flex-col items-center justify-center gap-1">
      <p className="text-sm text-accent">Resurs:</p>
      <p className="text-base font-medium  text-primary">{resource.name}</p>
    </div>
  );
};
