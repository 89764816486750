import { Container } from '@/components/Container';
import { Footer } from '@/components/Footer';

import PageNotFound from '../assets/page_not_found.svg';

export const ErrorPage = () => {
  return (
    <Container>
      <div className="flex grow flex-col items-center justify-center gap-10">
        <img className="w-2/5" src={PageNotFound} />
        <p className="text-xl  text-primary">Page not found</p>
      </div>
      <Footer />
    </Container>
  );
};
