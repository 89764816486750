import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  init,
  Replay,
  BrowserTracing,
  reactRouterV6Instrumentation,
} from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import { useAppStore } from './store/app.store';

import '@total-typescript/ts-reset';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://1d64bba057bd4f6e8fa5359eee25c5c7@o1409683.ingest.sentry.io/4505477140578304',
  tunnel: 'https://sentry.allbin.se',
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https:all-binary.io/api/'],
      routingInstrumentation: reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Main: React.FC = () => {
  const theme = useAppStore((state) => state.theme);

  return (
    <main
      data-theme={theme}
      className="flex h-screen w-screen items-center bg-neutral"
    >
      <App />
    </main>
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Main />
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
