import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '@components/Header';
import { QRIdDisplay } from '@components/QRIdDisplay';
import { RouterParams } from '@/App';
import { useResource } from '@/utils/useResource';
import Spinner from '@/components/Spinner';
import { useAccount, useMsal } from '@azure/msal-react';
import CheckInButton from '@/components/CheckInButton';
import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

export const ReportAttendance = () => {
  const navigate = useNavigate();

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { qrId } = useParams<RouterParams>();
  const { data: resource, isFetching, isFetched } = useResource(qrId);

  const handleError = useCallback(
    (e: Error) => {
      Sentry.captureException(e);
      navigate('error');
    },
    [navigate],
  );

  const handleSuccess = useCallback(() => {
    navigate('success');
  }, [navigate]);

  // const reportAttendance = useCallback(
  //   async (check_in: boolean) => {
  //     if (!resource) {
  //       Sentry.captureException('Resource not found');
  //       navigate('error');
  //       return;
  //     }

  //     const result = await instance.acquireTokenSilent({
  //       scopes: [],
  //       account: accounts[0],
  //     });

  //     if (!account?.name || !account?.username) {
  //       return;
  //     }

  //     const attendance: Attendance = {
  //       id_token: result.idToken,
  //       id: resource.externalId,
  //       friendly_name: resource.name,
  //       user_name: account.name,
  //       user_email: account.username,
  //       check_in,
  //     };

  //     Sentry.addBreadcrumb({
  //       message: 'Report attendance',
  //       category: 'user_action',
  //       data: attendance,
  //     });
  //     console.log('Report attendance', attendance);

  //     await mutateAsync(attendance)
  //       .then(() => {
  //         if (check_in) {
  //           setCookie('checked_in', DateTime.now().toISO(), {
  //             path: '/',
  //             maxAge: CHECK_IN_TIMEOUT,
  //           });
  //         } else {
  //           removeCookie('checked_in', { path: '/' });
  //         }

  //         navigate('success');
  //       })
  //       .catch((e) => {
  //         Sentry.captureException(e);
  //         navigate('error');
  //       });
  //   },
  //   [
  //     resource,
  //     instance,
  //     accounts,
  //     account?.name,
  //     account?.username,
  //     mutateAsync,
  //     navigate,
  //     setCookie,
  //     removeCookie,
  //   ],
  // );

  if (isFetching || !isFetched) {
    return (
      <>
        <Header>Laddar resurs...</Header>
        <div className="flex items-center justify-center">
          <Spinner className="text-primary" />
        </div>
      </>
    );
  }

  if (!resource) {
    return (
      <>
        <Header>QR-koden finns inte</Header>
      </>
    );
  }

  if (!account) {
    return (
      <>
        <Header>Du är inte inloggad</Header>
      </>
    );
  }

  return (
    <>
      <Header>
        Välkommen {account?.name}! Klicka på rapportera närvaro knappen för att
        rapportera din närvaro.
      </Header>
      <QRIdDisplay resource={resource} />
      <div className="flex w-full flex-row items-stretch justify-center gap-4">
        <CheckInButton
          resource={resource}
          account={account}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      </div>
    </>
  );
};
