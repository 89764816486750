import React, { FC } from 'react';

type ContainerProps = {
  children: React.ReactNode;
};

export const Container: FC<ContainerProps> = ({ children }) => {
  return (
    <div className=" flex h-full w-full flex-col justify-between p-10 text-center">
      {children}
    </div>
  );
};
