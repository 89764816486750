import { useIsAuthenticated } from '@azure/msal-react';
import { FC } from 'react';
import { Login } from './pages/Login';

interface Props {
  children: React.ReactNode;
}

export const ProtectedRoute: FC<Props> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <Login />;
  }

  return <>{children}</>;
};
