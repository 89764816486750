import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type Themes = 'ronnebyLight' | 'ronnebyDark';

interface AppState {
  qrId: string;
  setQrId: (qrId: string) => void;
  theme: Themes;
  setTheme: (theme: Themes) => void;
}

export const useAppStore = create<AppState>()(
  devtools(
    persist(
      (set) => ({
        theme: 'ronnebyLight',
        setTheme: (theme) => set({ theme }),
        qrId: '',
        setQrId: (qrId) => set({ qrId }),
      }),
      {
        name: 'app-storage',
      },
    ),
  ),
);
