import React, { useCallback } from 'react';
import { Container } from '@/components/Container';
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { Loading } from '@/components/Loading';
import AzureIcon from '../assets/azure.svg';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

export const Login: React.FC = () => {
  const { instance, inProgress } = useMsal();

  const handleLogin = useCallback(async () => {
    try {
      await instance.loginRedirect();
    } catch (error) {
      console.log(error);
    }
  }, [instance]);

  // const { data: resource } = useResource(qrId);

  // Parama and QR related
  if (inProgress !== InteractionStatus.None) {
    return (
      <Container>
        <div className="flex grow items-center justify-center">
          <Loading />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        Logga in för att meddela var och när resursen används. Detta sker för
        att hålla koll på våra resurser och för din egen trygg- och säkerhet.
      </Header>
      <button className="btn btn-primary" onClick={handleLogin}>
        <img src={AzureIcon} alt="azure icon" />
        <p>Logga in</p>
      </button>
      <Footer />
    </Container>
  );
};
