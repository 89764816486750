import { Container } from '@/components/Container';
import { Footer } from '@/components/Footer';
import { Outlet } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import LogoutIcon from '@/assets/LogoutIcon';

export const Attendance = () => {
  const { instance } = useMsal();

  return (
    <Container>
      {/* Outlet to display all sub pages */}
      <Outlet />
      <div className="mt-8 flex justify-center">
        <button
          className="btn btn-primary btn-outline btn-xs text-xs"
          onClick={() =>
            instance.logoutRedirect({ onRedirectNavigate: () => false })
          }
        >
          <LogoutIcon className="stroke-primary" />
          Logga ut
        </button>
      </div>
      <Footer />
    </Container>
  );
};
