import { Header } from '@/components/Header';
import { QRIdDisplay } from '@/components/QRIdDisplay';

import ErrorReportSvg from '../../assets/error_report.svg';
import { useNavigate } from 'react-router-dom';

export const ReportError = () => {
  const navigate = useNavigate();

  return (
    <div className="flex grow flex-col">
      <Header />
      <div className="mb-5 flex grow flex-col items-center justify-center gap-8">
        <div className="w-2/5">
          <img src={ErrorReportSvg} alt="Something went wrong" />
        </div>
        <p className="text-sm text-accent">
          Rapporteringen misslyckades. Kontakta din arbetsledare
        </p>
      </div>
      <button
        className="btn btn-primary text-white"
        onClick={() => navigate(-1)}
      >
        Försök igen
      </button>
      <QRIdDisplay />
    </div>
  );
};
