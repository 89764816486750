import { useConfigStore } from '@/store/config.store';
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export interface Attendance {
  id_token: string;
  id: string;
  friendly_name: string;
  user_name: string;
  user_email: string;
  check_in: boolean;
}

export const useAttendance = () => {
  const apiUrl = useConfigStore((state) => state.API_URL);

  return useMutation({
    mutationFn: async (attendance: Attendance) => {
      const { id, id_token, friendly_name, user_name, user_email, check_in } =
        attendance;

      const timestamp = DateTime.now().toUTC().toISO();

      const formData = new FormData();
      formData.append('id_token', id_token);
      formData.append('id', id);
      formData.append('friendly_name', friendly_name);
      formData.append('user_name', user_name);
      formData.append('user_email', user_email);
      if (timestamp) {
        formData.append('timestamp', timestamp);
      }
      formData.append('check_in', check_in ? 'true' : 'false');

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Error submitting attendance');
      }

      return response.json();
    },
  });
};
