const Logo: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={555.435}
    height={251.581}
    viewBox="0 0 555.435 251.581"
    className={className}
    fill="none"
  >
    <g>
      <path
        d="M983.566 1245.28h88.804V836.082H970.363v255.598L859.965 963.281h-15.602L733.965 1091.68V836.082h-102v409.198h88.801l131.402-151.8 131.398 151.8M1268.56 836.082h-102v409.198h102V836.082M1674.76 836.082h-312v409.198h102V911.082h210v-75"
        style={{
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(.13333 0 0 -.13333 -84.262 347.134)"
      />
      <path
        d="M1841.55 873.879c0-49.199-10.2-91.797-40.2-121.797-27-27-64.2-39.602-134.4-34.211v80.41c50.4-8.402 72.6 22.801 72.6 73.801v373.198h102V873.879M2382.75 1040.68c0 98.4-79.8 210-234 210-154.21 0-234.61-111-234.61-210 0-104.399 84.61-210 234.61-210 147 0 234 105 234 210zm-173.4 321.6c-21.6 0-39-17.4-39-39s17.4-39 39-39 39 17.4 39 39-17.4 39-39 39zm-121.8 0c-21.6 0-39-17.4-39-39s17.4-39 39-39 39 17.4 39 39-17.4 39-39 39zm190.2-321.6c0-75-51.6-124.801-129-124.801-78 0-129.6 49.801-129.6 124.801s51.6 124.8 129.6 124.8c77.4 0 129-49.8 129-124.8M2826.15 1170.28h-148.2V836.082h-102v334.198h-148.2v75h398.4v-75M3190.94 836.082h-301.8v409.198h297.6v-75h-195.59v-85.8h186.6v-75h-186.6v-98.398h199.79v-75M3479.54 1053.28l180.6-217.198h-127.8l-162 191.998V836.082h-102v409.198h102v-185.4h1.2l129 185.4h112.2l-133.2-192M4114.93 836.082h-85.8l-226.2 262.198V836.082h-101.99v409.198h93.59l218.4-250.198v250.198h102V836.082M4312.34 836.082h-102v409.198h102V836.082M4617.13 1053.28l180.6-217.198h-127.81l-161.99 191.998V836.082h-102v409.198h102v-185.4h1.19l129.01 185.4h112.21l-133.21-192"
        style={{
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(.13333 0 0 -.13333 -84.262 347.134)"
      />
    </g>
    <path
      d="m3200.67 2031.24-388.3-38.2 188.3-373.6c-11.81-29.5-53-47.1-82.41-38.3l-230.1 326.7v2.3l-.5-.9-230.09-326.7c-29.41-8.8-70.6 8.8-82.41 38.3l188.3 373.6-388.3 38.2c-14.69 20.6-14.69 73.6 0 91.2l388.3 38.3-188.3 367.7c14.8 29.5 55.9 50.1 91.21 41.2l221.29-320.5.6-1.2 221.21 320.3c35.3 8.8 76.49-11.8 91.2-41.2l-188.3-367.7 388.3-38.3c14.79-17.6 14.79-70.6 0-91.2"
      style={{
        fill: '#040606',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(.13333 0 0 -.13333 -84.262 347.134)"
    />
    <path
      d="m3200.67 2031.24-388.3-38.2 188.3-373.6c-11.81-29.5-53-47.1-82.41-38.3l-230.1 326.7v2.3l-.5-.9-230.09-326.7c-29.41-8.8-70.6 8.8-82.41 38.3l188.3 373.6-388.3 38.2c-14.69 20.6-14.69 73.6 0 91.2l388.3 38.3-188.3 367.7c14.8 29.5 55.9 50.1 91.21 41.2l221.29-320.5.6-1.2 221.21 320.3c35.3 8.8 76.49-11.8 91.2-41.2l-188.3-367.7 388.3-38.3c14.79-17.6 14.79-70.6 0-91.2z"
      style={{
        fill: 'none',
        stroke: '#fff',
        strokeWidth: 60,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
        strokeOpacity: 1,
      }}
      transform="matrix(.13333 0 0 -.13333 -84.262 347.134)"
    />
    <path
      d="M3200.73 2121.77 2812.38 2160l188.3 367.75c-14.71 29.43-55.91 50.02-91.21 41.19l-221.27-320.53V1907.1l230.1-326.66c29.42-8.83 70.59 8.84 82.38 38.25l-188.3 373.62 388.35 38.25c14.71 20.6 14.71 73.56 0 91.21"
      style={{
        fill: '#1c4e9d',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(.13333 0 0 -.13333 -84.262 347.134)"
    />
    <path
      d="M3200.73 2121.77 2812.38 2160l188.3 367.75c-14.71 29.43-55.91 50.02-91.21 41.19l-221.27-320.53V1907.1l230.1-326.66c29.42-8.83 70.59 8.84 82.38 38.25l-188.3 373.62 388.35 38.25c14.71 20.6 14.71 73.56 0 91.21z"
      style={{
        fill: 'none',
        stroke: '#1c4e9d',
        strokeWidth: 10,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
        strokeOpacity: 1,
      }}
      transform="matrix(.13333 0 0 -.13333 -84.262 347.134)"
    />
    <path
      d="m2687.66 2249.81-221.26 320.55c-35.29 8.83-76.46-11.76-91.18-41.18l188.26-367.75-388.31-38.25c-14.7-17.66-14.7-70.61 0-91.21l388.31-38.23-188.26-373.63c11.76-29.43 52.96-47.08 82.39-38.24l230.05 326.65 94.87 166.93-94.87 174.36"
      style={{
        fill: '#66c991',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      transform="matrix(.13333 0 0 -.13333 -84.262 347.134)"
    />
    <path
      d="m2687.66 2249.81-221.26 320.55c-35.29 8.83-76.46-11.76-91.18-41.18l188.26-367.75-388.31-38.25c-14.7-17.66-14.7-70.61 0-91.21l388.31-38.23-188.26-373.63c11.76-29.43 52.96-47.08 82.39-38.24l230.05 326.65 94.87 166.93z"
      style={{
        fill: 'none',
        stroke: '#66c991',
        strokeWidth: 10,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
        strokeOpacity: 1,
      }}
    />
  </svg>
);

export default Logo;
