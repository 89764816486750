import { Attendance, useAttendance } from '@/utils/useAttendance';
import { QrData } from '@/utils/useResource';
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { FaUserCheck, FaUserSlash } from 'react-icons/fa6';
import * as Sentry from '@sentry/react';

const CHECK_IN_TIMEOUT = 60 * 60 * 12; // 12h

interface Props {
  resource: QrData;
  account: AccountInfo;
  onSuccess: () => void;
  onError: (e: Error) => void;
}
const CheckInButton: React.FC<Props> = ({
  resource,
  account,
  onSuccess,
  onError,
}) => {
  const { instance } = useMsal();
  const [cookies, setCookie, removeCookie] = useCookies(['checked_in']);
  const { mutateAsync } = useAttendance();

  const reportAttendance = useCallback(
    async (check_in: boolean) => {
      const result = await instance.acquireTokenSilent({
        scopes: [],
        account,
      });

      if (!account.name || !account.username) {
        return;
      }

      const attendance: Attendance = {
        id_token: result.idToken,
        id: resource.externalId,
        friendly_name: resource.name,
        user_name: account.name,
        user_email: account.username,
        check_in,
      };

      Sentry.addBreadcrumb({
        message: 'Report attendance',
        category: 'user_action',
        data: attendance,
      });

      await mutateAsync(attendance)
        .then(() => {
          if (check_in) {
            setCookie('checked_in', DateTime.now().toISO(), {
              path: '/',
              maxAge: CHECK_IN_TIMEOUT,
            });
          } else {
            removeCookie('checked_in', { path: '/' });
          }
          onSuccess();
        })
        .catch((e) => {
          onError(e);
        });
    },
    [
      instance,
      account,
      resource.externalId,
      resource.name,
      mutateAsync,
      onSuccess,
      setCookie,
      removeCookie,
      onError,
    ],
  );

  if (cookies.checked_in) {
    return (
      <button
        className="btn btn-primary grow"
        onClick={() => reportAttendance(false)}
      >
        <FaUserSlash />
        <p>Utcheckning</p>
      </button>
    );
  }

  return (
    <button
      className="btn btn-primary grow"
      onClick={() => reportAttendance(true)}
    >
      <FaUserCheck />
      <p>Incheckning</p>
    </button>
  );
};

export default CheckInButton;
